import { returnScreenSize } from '../utils/screen';
import { FlyoutGroups } from '../utils/services/flyout-groups';
import { PatternlibSettings, PatternlibTheme } from '../utils/services/settings';
import { setRootAttribute } from '../utils/utils';

/**
 * Method used to setup Patternlib globals.
 * @returns
 */
export function setupPatternlibRuntime(): void {
  // TODO: Remove with 3.0? Check again before removal.
  if (window != null) {
    const screenSize = returnScreenSize();
    setRootAttribute('breakpoint', screenSize);
    window.addEventListener('resize', function () {
      const screenSize = returnScreenSize();
      setRootAttribute('breakpoint', screenSize);
    });

    const context =
      document?.documentElement?.getAttribute('context') ?? document?.documentElement?.getAttribute('data-context');
    if (context == null || context === '') {
      setRootAttribute('context', 'portal');
      setRootAttribute('data-context', 'portal');
    }

    const theme =
      document?.documentElement?.getAttribute('theme') ?? document?.documentElement?.getAttribute('data-theme');
    if (theme === null || theme === '') {
      setRootAttribute('theme', 'corporate');
      setRootAttribute('data-theme', 'corporate');
    }

    window['PatternlibFlyoutGroups'] = new FlyoutGroups();

    if (!('Patternlib' in window)) {
      window['Patternlib'] = new PatternlibService();
    }
  }
}

/**
 * PatternlibService class.
 */
export class PatternlibService {
  /**
   * Updates the root attributes.
   */
  static updateSettings(newSettings: PatternlibSettings): void {
    const theme = newSettings.theme;
    const context = newSettings.context;
    const mode = newSettings.mode;
    PatternlibService.updateRootAttribute(context, theme, mode);
  }

  static setTheme(theme: PatternlibTheme | string): void {
    setRootAttribute('theme', theme);
  }

  /**
   * Pass through method for FlyoutGroups.
   */
  static registerFlyout(el: any, groupName?: string): void {
    window['PatternlibFlyoutGroups'].registerFlyout(el, groupName);
  }

  static unregisterFlyout(el: any, groupName?: string): void {
    window['PatternlibFlyoutGroups'].unregisterFlyout(el, groupName);
  }

  static closeAllOtherFlyouts(el: any, groupName?: string): void {
    window['PatternlibFlyoutGroups'].closeAllOtherGroupFlyouts(el, groupName);
  }

  static updateRootAttribute(context: string, theme: string, mode: string): void {
    if (context != null) {
      setRootAttribute('context', context); // TODO: Remove with 3.0
      setRootAttribute('data-context', context);
    }
    if (theme != null) {
      setRootAttribute('theme', theme); // TODO: Remove with 3.0
      setRootAttribute('data-theme', theme);
    }
    if (mode != null) {
      setRootAttribute('data-mode', mode);
    }
  }

  /**
    Query prefers-color-scheme.
  */
  public static getPreferedColorScheme(): string {
    // guard for SSR
    if (typeof window === 'undefined') {
      return 'light';
    }

    const mediaQuery = matchMedia('(prefers-color-scheme: dark)');
    return mediaQuery.matches ? 'dark' : 'light';
  }
}
