// Copy from tailwind config if a breakpoint changed.
const cleanedBreakpoints = {
  sm: '576',
  md: '768',
  lg: '992', // only for CORPORATE (DWL) context and header component.
  xl: '1280',
  xxl: '1400',
  tablet: '768',
  desktop: '1280',
};

export function getBreakpointStart(breakpoint: string): number {
  if (!(breakpoint in cleanedBreakpoints)) {
    throw new Error(`Breakpoint ${breakpoint} not found. Please check your tailwind config.`);
  }
  return cleanedBreakpoints[breakpoint];
}

/**
 * Defines one of three screen size types used internally.
 */
export type ScreenSize = 'isMobile' | 'isTablet' | 'isDesktop';

export function innerWidthBetween(min: number, max: number): boolean {
  return window.innerWidth >= min && window.innerWidth < max;
}

function isMobileBreakpoint(): boolean {
  return innerWidthBetween(0, getBreakpointStart('tablet'));
}

function isTabletBreakpoint(): boolean {
  return innerWidthBetween(getBreakpointStart('tablet'), getBreakpointStart('desktop'));
}

/**
 * Returns the current ScreenSize.
 */
export function returnScreenSize(): ScreenSize {
  if (isMobileBreakpoint()) {
    return 'isMobile';
  } else if (isTabletBreakpoint()) {
    return 'isTablet';
  } else {
    return 'isDesktop';
  }
}
