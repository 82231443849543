import { PatternlibOverlayContainer } from '@/components/patternlib-overlay-container/patternlib-overlay-container';

export const DEFAULT_GROUP_NAME = 'FLYOUT_DEFAULT_GROUP';

/**
 * @internal
 */
export type ElementWithFlyoutClose = { closeFlyoutmenu(): Promise<void>; hostElement: HTMLElement };

/**
 * Allows to assign flyouts to groups and helps with controlling those groups.
 *
 * @internal
 */
export class FlyoutGroups {
  public groups: Map<string, ElementWithFlyoutClose[]> = new Map();

  /**
   * Register a new flyout component.
   * @param el Component.
   * @param groupName Flyout group name.
   */
  registerFlyout(el: any, groupName?: string): void {
    const existingEls = this.groups.get(groupName || DEFAULT_GROUP_NAME);
    const els = existingEls != null ? [...existingEls, el] : [el];
    const group = groupName || DEFAULT_GROUP_NAME;

    this.groups.set(group, els);
  }

  /**
   * Unregister a flyout component from the specified group
   * @param el Component.
   * @param groupName Flyout group name.
   */
  unregisterFlyout(el: any, groupName?: string): void {
    const group = groupName || DEFAULT_GROUP_NAME;
    const els = this.groups.get(group);
    const index = els?.findIndex(x => x.hostElement === el) ?? -1;
    if (index >= 0) {
      const newEls = els.splice(index, 1);
      this.groups.set(group, newEls);
    }
  }

  /**
   * Close all flyout components, except the provided 'el'.
   * @param el Component.
   * @param groupName Flyout group name.
   */
  closeAllOtherGroupFlyouts(el: PatternlibOverlayContainer, groupName?: string): void {
    this.groups.get(groupName || DEFAULT_GROUP_NAME)?.forEach(async component => {
      if (component.hostElement !== el.hostElement) {
        await component.closeFlyoutmenu();
      }
    });
  }
}
