/**
 * @internal
 */
export function format(first: string, middle: string, last: string): string {
  return (first || '') + (middle ? ` ${middle}` : '') + (last ? ` ${last}` : '');
}

/**
 * @internal
 */
export function getRootAttribute(attributeName: string): string {
  return document.documentElement?.getAttribute(attributeName);
}

/**
 * @internal
 */
export function setRootAttribute(attributeName: string, attributeValue: string) {
  if (document != null) {
    const root = document.documentElement;
    root.setAttribute(attributeName, attributeValue);
  }
}

/**
 * @internal
 */
export function removeRootAttribute(attributeName: string) {
  if (document != null) {
    const root = document.documentElement;
    root.removeAttribute(attributeName);
  }
}

/**
 * @internal
 */
export function observeRootChange(attributeName: string, mutationsList: Array<MutationRecord>): string {
  for (const mutation of mutationsList) {
    if (mutation.attributeName === attributeName) {
      return document.documentElement?.getAttribute(attributeName);
    }
  }
  return null;
}

/**
 * @internal
 */
export function parseStringOrObject(value: string | unknown): any {
  if (typeof value === 'string') {
    return JSON.parse(value);
  }

  return value;
}
